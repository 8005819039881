import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'
import { translateSingular } from '/i18n/translations'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { HeadingLg } from '/features/buildingBlocks/Heading'

import styles from './ProcedureSlider.css'

export function ProcedureSlider({
  steps,
  id = undefined,
  text = undefined,
  title = undefined,
  layoutClassName = undefined
}) {
  const { sliderRef } = useKeenSliderRefs({
    interactionTitle: 'procedure-slider',
    slidesNum: steps.length,
    slidesPerView: 'auto',
    origin: 0
  })

  return (
    <div className={cx(styles.component, layoutClassName)} {...{ id }}>
      <ContainerLg>
        {(title || text) && (
          <div className={styles.header}>
            {title && <HeadingLg h={3} {...{ title }} />}
            {text && <p className={styles.headerText}>{text}</p>}
          </div>
        )}

        <div ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
          {steps?.map(({ styleContext, stepTitle, title, text }, index) => (
            <Slide key={index} layoutClassName={styles.slideLayout} {...{ styleContext }}>
              <Card
                {...{ stepTitle, title, text }}
                layoutClassName={styles.cardLayout}
              />
            </Slide>
          ))}
        </div>
      </ContainerLg>
    </div>
  )
}

function Slide({ children, styleContext, layoutClassName }) {
  return (
    <div
      tabIndex={0}
      className={cx(styles.componentSlide, 'keen-slider__slide', layoutClassName)}
      data-style-context={styleContext || 'blue'}
      {...{ children }}
    />
  )
}

function Card({
  title,
  text,
  stepTitle = undefined,
  layoutClassName = undefined
}) {
  return (
    <div className={cx(styles.componentCard, layoutClassName)}>
      {stepTitle && <strong className={styles.stepTitle}>{stepTitle}</strong>}
      <strong className={styles.title}>{title}</strong>
      <p className={styles.text}>{text}</p>
    </div>
  )
}

export function getProcedureSlideContent(language) {
  return ({
    default: [
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 1 }),
        title: translateSingular(language, 'procedure-title-you-apply'),
        text: translateSingular(language, 'procedure-default-text-1')
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 2 }),
        title: translateSingular(language, 'procedure-title-interview'),
        text: translateSingular(language, 'procedure-default-text-2')
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 3 }),
        title: translateSingular(language, 'procedure-title-our-offer'),
        text: translateSingular(language, 'procedure-default-text-3')
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 4 }),
        title: translateSingular(language, 'procedure-title-welcome'),
        text: translateSingular(language, 'procedure-default-text-4')
      },
    ],

    starter: [
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 1 }),
        title: translateSingular(language, 'procedure-title-you-apply'),
        text: translateSingular(language, 'procedure-starter-text-1'),
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 2 }),
        title: translateSingular(language, 'procedure-title-assessment'),
        text: translateSingular(language, 'procedure-starter-text-2'),
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 3 }),
        title: translateSingular(language, 'procedure-title-interview'),
        text: translateSingular(language, 'procedure-starter-text-3'),
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 4 }),
        title: translateSingular(language, 'procedure-title-our-offer'),
        text: translateSingular(language, 'procedure-starter-text-4'),
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 5 }),
        title: translateSingular(language, 'procedure-title-welcome'),
        text: translateSingular(language, 'procedure-starter-text-5'),
      },
    ],

    traineeship: [
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 1 }),
        title: translateSingular(language, 'procedure-title-you-apply'),
        text: translateSingular(language, 'procedure-traineeship-text-1'),
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 2 }),
        title: translateSingular(language, 'procedure-title-assessment'),
        text: translateSingular(language, 'procedure-traineeship-text-2'),
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 3 }),
        title: translateSingular(language, 'procedure-title-interview'),
        text: translateSingular(language, 'procedure-traineeship-text-3'),
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 4 }),
        title: translateSingular(language, 'procedure-title-selection-day'),
        text: translateSingular(language, 'procedure-traineeship-text-4'),
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 5 }),
        title: translateSingular(language, 'procedure-title-our-offer'),
        text: translateSingular(language, 'procedure-traineeship-text-5'),
      },
      {
        stepTitle: translateSingular(language, 'procedure-step', { step: 6 }),
        title: translateSingular(language, 'procedure-title-welcome'),
        text: translateSingular(language, 'procedure-traineeship-text-6'),
      },
    ]
  })
}
